import React from "react";
import Layout from "utils/Layout";
import { HistoryAppPrivacy } from "components/HistoryApp";
import { policy } from "staticContent/historyApp";
import "styles/components/privacyPolicy.scss";

const IndexPage = () => (
  <Layout subTitle={policy.pageTitle}>
    <HistoryAppPrivacy />
  </Layout>
);

export default IndexPage;
